.parent {
    font-family: Verdana, sans-serif;
    text-align: center;
}
.header {
    color: rgb(35, 145, 160);
    font-size: 5rem;
    height: 100px;
    margin: 2rem;
}

.description {
    font-style: italic;
    font-size: 2rem;
    color:rgb(17, 72, 79);
}